import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../shared/services/auth.service';
import { Router } from '@angular/router';
import { BlankSimplywhiteComponent } from '../../../@pages/layouts';
import { P } from '@angular/core/src/render3';


@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent extends BlankSimplywhiteComponent implements OnInit {
  userName;
  password;
  confirmPassword;
  token : any;
  errors : any[];
  ngOnInit(){
    let doc = this;
    this.activatedRoute.queryParamMap.subscribe((data) => {
      if(data['params']){
        if(data['params']['token']){
          doc.token = data['params']['token'];
        }
      }
    });
  }

  submitChange(invalid){
    if(invalid){
      alert("Validation Failed.");
      return;
    }
    let formData = {email:this.userName,token: this.token,password:this.password,password_confirmation: this.confirmPassword};
    this.authService.forgotUpdate(formData).subscribe(res => {
      this.messageService.success("Password updated successfully.", {Position: 'top', Style: 'bar', Duration: 3000});
      this.backToLogin();
    },
    err => {
      this.errors = [];
      this.errors = this.errorService.getErrors(err);
      this.messageService.error("Password change failed.", {Position: 'top', Style: 'bar', Duration: 3000});
    });
  }

  submitForgot(){
    this.authService.forgotToken(this.userName).subscribe(res => {
      this.messageService.success("Please check your email!", {Position: 'top', Style: 'bar', Duration: 3000});
      this.authService.isForgotPassword = false;
    },
    err => {
      this.messageService.error("Password change request failed.", {Position: 'top-right', Style: 'flip', Duration: 5000});
      this.errors = [];
      this.errors = this.errorService.getErrors(err);
      console.log(err);
    });
  }

  backToLogin(){
    this.authService.isForgotPassword = false;
    this.router.navigate(['/login']);
  }
  
}
