import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';

import { LocalStoreService } from './local-store.service';
import { MarkerWithLabel } from "../../../../node_modules/markerwithlabel";
import { environment } from '../../../environments/environment';
import { MapService } from './map.service';
import { MenuService } from './menu.service';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // Only for demo purpose
  authenticated = false;
  token : any;
  isForgotPassword = false;

  constructor(
    private http: HttpClient,
     private store: LocalStoreService,
     private mapService : MapService,
     private menuService : MenuService,
     private notificationService : NotificationService) {
    this.checkAuth();
  }

  checkAuth() {
    this.authenticated = !!this.store.getItem('currentUser');
  }

  getUser() {
    return of(this.store.getItem('currentUser'));
  }

  getUserId(){
    const user : any = this.store.getItem('currentUser');
    if(!user){
      return null;
    }
    return user.id;
  }

  getUserRole() {
    const user: any = this.store.getItem('currentUser');
    if(!user){
      return null;
    }
    return user.system_role;
  }

  forgotToken(username){
    return this.http.post(`${environment.baseUrl}/auth/forgot/request`,{email:username});
  }

  forgotUpdate(formData){
    return this.http.post(`${environment.baseUrl}/auth/forgot/reset`,formData);
  }

  signin(credentials) {
    return this.http.post<any>(`${environment.baseUrl}/auth/login`, { email: credentials.email, password: credentials.password })
      .pipe(map(res => {
        console.log("UserLogin: ",res);
        const user = res.data.user;
        const token = res.data.api_key;
        const id = res.data.user.id;
        const system_role = res.data.system_role['id'];
        this.token = token;
          // login successful if there's a jwt token in the response
          if (user && token) {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              this.store.setItem('currentUser', {...user, token,system_role,id});
              this.mapService.viewAssets = true;
              this.mapService.viewOpen = true;
              this.authenticated = true;
              this.menuService.populateLinks(this.notificationService,this);
          }

          return user;
      }));
  }
  signout() {
    let __this = this;
    this.authenticated = false;
    this.store.setItem('currentUser', null);
    this.store.clear();
    localStorage.clear();
    this.mapService.assetSearchText = "";
    this.mapService.followedAssetID = null;
    this.mapService.closestList = new Map<number,any>();
    this.mapService.closest = null;
    this.mapService.updateTrackers();
    if(this.mapService.radiusMarker){
      this.mapService.radiusMarker.setMap(null);
    }
    if(this.mapService.markerCircle){
      this.mapService.markerCircle.setMap(null);
    }
    this.mapService.markers.forEach((m: MarkerWithLabel) => {
      m.setMap(__this.mapService.map);
    });
    this.mapService.radiusMarker = null;
    this.mapService.markerCircle = null;
    this.mapService.updateTrackers();
    //this.router.navigateByUrl('/session/login');
  }
}
