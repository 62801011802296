import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../../shared/services/notification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  notifications : any;

  constructor(
    private notificationService : NotificationService,
    private router : Router
  ) {}

  ngOnInit() {
    this.notificationService.getAll({}).subscribe(res => {
      this.notifications = res['data']['data'];
    });
  }

  navigateTest(){
    this.router.navigate(['tracking']);
  }

}
