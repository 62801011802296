import { Injectable } from '@angular/core';
import { Roles, RoleAccess } from '../models/roles.model';

@Injectable()
export class RoleService {
  private roles: Roles = {
    1: {
      role: 'Administrator',
      defaultRoute: 'tracking',
      routeAccess: {
        dashboard: true,
        tracking: true,
        diagnostics: true,
        alerts: true,
        support: true,
        modification: true,
        reports:true,
        ['trail-reports']: true,
        ['location-reports']: true,
        ['trip-reports']: true,
        geofencing: true,
        settings: true,
        ['vehicle']: true,
        ['user']: true,
        ['orgaisation']: true,
        ['tracker']: true,
        ['alerts-rules']: true
      }
    },
    2: {
      role: 'Organisation Owner',
      defaultRoute: 'tracking',
      routeAccess: {
        dashboard: true,
        tracking: true,
        diagnostics: true,
        alerts: true,
        support: true,
        modification: true,
        reports:true,
        ['trail-reports']: true,
        ['location-reports']: true,
        ['trip-reports']: true,
        geofencing: true,
        settings: true,
        ['vehicle']: true,
        ['user']: false,
        ['orgaisation']: false,
        ['tracker']: false,
        ['alerts-rules']: true
      }
    },
    3: {
      role: 'Technician',
      defaultRoute: 'modification/tracker',
      routeAccess: {
        dashboard: false,
        tracking: false,
        diagnostics: false,
        alerts: false,
        support: false,
        modification: true,
        reports:false,
        ['trail-reports']: false,
        ['location-reports']: false,
        ['trip-reports']: false,
        geofencing: false,
        settings: true,
        ['vehicle']: true,
        ['user']: false,
        ['orgaisation']: false,
        ['tracker']: true,
        ['alerts-rules']: false
      }
    },
    
  };

  constructor() { }

  getAccess(role: number): RoleAccess {
    return <RoleAccess>this.roles[role];
  }
}
