import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { Note } from '../../../@pages/components/quickview/note';
import { chatHistory, chatMessage } from '../../../@pages/components/quickview/message';
import { QuickviewService } from '../../../@pages/components/quickview/quickview.service';
import { pagesToggleService } from '../../../@pages/services/toggler.service';
import { Alert } from '../../../shared/models/alert.model';
import { MenuService } from '../../../shared/services/menu.service';
import { AuthService } from '../../../shared/services/auth.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { isArray } from 'rxjs/internal/util/isArray';


@Component({
  selector: 'alerts-quickview',
  templateUrl: './quickview.component.html',
  styleUrls: ['./quickview.component.scss']
})
export class QuickviewAlertsComponent implements OnInit,OnDestroy {
  subscriptions: Array<Subscription> = [];
  isOpen:boolean = false;
  noteList = [];
  noteDeleteList = []
  //Single
  selectedNote:Note;
  noteText = "";
  //List for deleting or CRUD functions
  deleteNoteMode:boolean = false;
  isNoteOpen = false;
  userList = [];
  chatHistory:chatHistory;
  userMessage;
  newMessage:chatMessage;

  @ViewChild('chatHistoryWrapper') chatHistoryWrapper: ElementRef;

  alerts : Alert[] = [];

  constructor(
    private _service: QuickviewService,
    private menuService: MenuService,
    private toggler:pagesToggleService,
    private notificationService : NotificationService,
    private authService : AuthService) {
    this.subscriptions.push(this.toggler.quickViewToggle.subscribe(message => { this.toggle() }));
  }
  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    for(const sub of this.subscriptions){
      sub.unsubscribe();
    }
  }
  ngOnInit() {
    this.notificationService.getAll({}).subscribe(res => {
      //console.log(res);
      this.alerts = res['data']['data'];
      if(isArray(this.alerts)){
        this.alerts = this.alerts.filter(value => !value.is_read);
      }
      //console.log(this.alerts);
    },
    err => {
      console.log(err);
    })
    // Retrieve posts from the API
    // this.subscriptions.push(this._service.getNotes().subscribe(notes => {
    //   this.noteList = notes;
    // }));
  
    // this.subscriptions.push(this._service.getUsers().subscribe(users => {
    //   this.userList = users;
    // }));

    // this.subscriptions.push(this._service.getChatMessages().subscribe(messages => {
    //   this.chatHistory = messages;
    // }));
  }
   toggle() {
    if(this.isOpen){
    	this.isOpen = false
    }
    else{
    	this.isOpen = true;
    }
  }

  closeNotification(alert : any){
    // let c = 0;
    // for(let alertCheck of this.alerts){
    //   if(alertCheck == alert){
    //     this.alerts.splice(c,1);
    //     break;
    //   }
    //   c++;
    // }
    // this.menuService.populateLinks(this.notificationService,this.authService);
  }

  popNote(item:Note):void{
    const index = this.noteDeleteList.indexOf(item);
    if (index !== -1) {
      this.noteDeleteList.splice(index, 1);
    }
  }

  pushNote(item:Note):void{
    this.noteDeleteList.push(item);
  }

  onSelectNote(item: Note): void {
    if(!this.deleteNoteMode){
      this.selectedNote = item;
      this.noteText = this.selectedNote.notes
      this.isNoteOpen = true;
    }
    
  }
  toggleNotesView():void{
    if(this.isNoteOpen){
      this.isNoteOpen = false;
      this.saveNote()
    }
    else
      this.isNoteOpen = true;
  }

  onCheck(e,item:Note):void{
    if(e.target.checked){
      this.pushNote(item);
    }else{
      this.popNote(item);
    }
  }

  composeNote():void{
    this.isNoteOpen = true;
    this.selectedNote = new Note;
    this.selectedNote.id = this.noteList.length + 1;
    this.selectedNote.date =  new Date();
    this.selectedNote.notes = "";
    this.noteText = "";
    this.noteList.push(this.selectedNote);
  }

  saveNote():void{
    this.selectedNote.notes = this.noteText;
  }

  deleteMode():void{
    if(this.deleteNoteMode)
      this.deleteNoteMode = false;
    else
      this.deleteNoteMode = true;
  }

  deleteNote():void{
    this.noteList = this.noteList.filter(item => this.noteDeleteList.indexOf(item)  === -1);
  }

  onMessageKeyPress(event){
    if (event.keyCode == 13) {
      if(this.userMessage){
        this.newMessage = new chatMessage;
        this.newMessage.from = "me";
        this.newMessage.date = "";
        this.newMessage.message = this.userMessage;
        this.chatHistory["log"].push(this.newMessage);
        this.userMessage = "";
        this.chatHistoryWrapper.nativeElement.scrollTop = this.chatHistoryWrapper.nativeElement.scrollHeight;
      }
    }
  }
}
