export class TrackedAsset{

    tracker: number;
      organisation: number;
      reference: string;
      nickname: string;
      make: string;
      model: string;
      year: string;
      color: string;
      type: string;
      base_lat: number;
      base_lng: number;
      base_address: string;
      gps: {
        longitude: number;
        latitude: number;
        altitude: string;
        angle: number;
        satellites: string;
        speed: number;
        address: string;
        timestamp: string;
      };
      properties: {
        ignition: boolean;
        movement: boolean;
        gsm_singal_strength: number;
        sleep_mode: boolean;
        ext_voltage: number;
        battery_voltage: number;
        odometer: number;
      }

    constructor(input?){
        input = input || {};
        this.tracker = input.tracker || '';
        this.organisation = input.organisation || '';
        this.reference = input.reference || '';
        this.nickname = input.nickname || '';
        this.make = input.make || '';
        this.model = input.model || '';
        this.year = input.year || '';
        this.color = input.color || '';
        this.type = input.type || '';
        this.base_lat = input.base_lat || '';
        this.base_lng = input.base_lng || '';
        this.base_address = input.base_address || '';
        this.gps = input.gps || {};
    }
}