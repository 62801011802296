import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { User } from '../models/user.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  isCustomTheme : boolean = false;
  silverTheme: any = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#F5F5F5"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#F5F5F5"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#BDBDBD"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#EEEEEE"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#E5E5E5"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9E9E9E"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#FFFFFF"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#DADADA"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9E9E9E"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#E5E5E5"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#EEEEEE"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#A4BEC9"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9E9E9E"
        }
      ]
    }
  ];

  constructor(private http : HttpClient) { }

  getAllUsers(){
    return this.http.get(`${environment.baseUrl}/user`).pipe(map(res =>{
      let tmp : User[] = [];
      for(let rsp of res['data']){
        tmp.push(new User(rsp));
      }
      return tmp;
    }
    ));
  }

  getUser(id : number){
    return this.http.get(`${environment.baseUrl}/user/`+id);
  }

  deleteUser(id : number){
    return this.http.delete(`${environment.baseUrl}/user/`+id);
  }

  createUser(formData){
    let tmp : number = formData['system_role'];
    formData['system_role'] = tmp;
    return this.http.post(`${environment.baseUrl}/user`,formData);
  }

  getSystemRoles(){
    return this.http.get(`${environment.baseUrl}/systemroles`);
  }

  updateUser(id : number, userData){
    let tmp : number = userData['system_role'];
    userData['system_role'] = tmp;
    return this.http.put(`${environment.baseUrl}/user/`+id, userData);
  }





}
