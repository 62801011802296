import { Injectable } from '@angular/core';
import { TrackedAsset } from '../models/tracked-asset.model';
import { MarkerWithLabel } from "../../../../node_modules/markerwithlabel";
import { HttpClient } from '@angular/common/http';
import MarkerClusterer from '@googlemaps/markerclustererplus';
declare function require(path: string): any;

@Injectable({
  providedIn: 'root'
})
export class MapService {

  markerCluster: MarkerClusterer;
  isLoading: boolean = false;
  markersReady: any[] = [];
  markers: Map<number, {}>;
  followedAsset: TrackedAsset;
  viewOpen = true;
  //zoomLevel = 12;
  map: any;
  followedAssetID = null;
  viewAssets = false;
  assetSearchText: string = "";
  tmpMarkers: any = [];
  collapsedTracker: TrackedAsset;
  geoJSON: any;
  activeGeoType: number = 0;
  radiusMarker: any;
  ids = [];
  //LiveSearch
  closest: any;
  circleTimer: any;
  markerCircle: any;
  closestList: Map<number, any> = new Map<number, any>();
  latestAssets: any[];
  trackedAssets: any[];
  trackedAssetsIds: any[] = [];
  sideMenuPageIndex:number = 0;
  markForCheck: boolean = false;

  updateCluster() {
    if (this.sideMenuPageIndex == 2){
      this.markerCluster.setMinimumClusterSize(30000);
      this.markerCluster.repaint();
      return;
    }
    if (this.markerCluster) {
      // this.markerCluster = new MarkerClusterer(this.map, this.markersReady, {
      //   imagePath:
      //     "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
      //   // styles: [{
      //   //   url: "https://github.com/googlemaps/v3-utility-library/blob/master/packages/markerclustererplus/images/m1.png",
      //   //   width: 53,
      //   //   height: 53,
      //   //   fontFamily: "comic sans ms",
      //   //   textSize: 15,
      //   //   textColor: "white"
      //   // }]
      // });
      // this.markerCluster.clearMarkers();
      // this.markerCluster.setIgnoreHidden(true);
      if(this.map.zoom >= 18){
        this.markerCluster.setMinimumClusterSize(30000);
      }
      else{
        this.markerCluster.setMinimumClusterSize(3);
      }
      // this.markerCluster.addMarkers(this.markersReady);
      this.markerCluster.repaint();
    }
    else {
      var mcOptions = {
          // gridSize: 50,
          // minimumClusterSize: 3,
          imagePath:
          "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m"
      };
      this.markerCluster = new MarkerClusterer(this.map, this.markersReady, mcOptions);
      this.markerCluster.setIgnoreHidden(true);
      this.markerCluster.setMinimumClusterSize(3);
      //console.log(this.markersReady);
    }
  }

  smoothZoom (map, level, cnt, mode) {
    let __this = this;
		//alert('Count: ' + cnt + 'and Max: ' + level);

		// If mode is zoom in
		if(mode == true) {

			if (cnt >= level) {
				return;
			}
			else {
				var z = google.maps.event.addListener(map, 'zoom_changed', function(event){
					google.maps.event.removeListener(z);
					__this.smoothZoom(map, level, cnt + 1, true);
				});
				setTimeout(function(){map.setZoom(cnt)}, 80);
			}
		} else {
			if (cnt <= level) {
				return;
			}
			else {
				var z = google.maps.event.addListener(map, 'zoom_changed', function(event) {
					google.maps.event.removeListener(z);
					__this.smoothZoom(map, level, cnt - 1, false);
				});
				setTimeout(function(){map.setZoom(cnt)}, 80);
			}
		}
	}   

  generateAssetsInRadius() {
    this.closest = null;
    this.closestList.clear();
    if (this.markers) {
      this.markers.forEach((m: MarkerWithLabel) => {
        let oldLat = m.data.tracker.gps.latitude;
        let oldLng = m.data.tracker.gps.longitude;
        let d = this.distance(this.radiusMarker.position.lat(), this.radiusMarker.position.lng(), oldLat, oldLng, 'K');
        if (d > (this.markerCircle.radius / 1000)) {
          m.setMap(null);
          m.setVisible(false);
        }
        else {
          m.setMap(this.map);
          m.setVisible(true);
        }
        this.closestList.set(d, m);
      });
    }

    let tmp = Array.from(this.closestList.keys()).sort();
    let tmpMap: Map<number, any> = new Map<number, any>();
    for (let i of tmp) {
      tmpMap.set(i, this.closestList.get(i));
    }
    this.closestList = tmpMap;
    //console.log(this.closestList);
  }

  filterAssets() {
    if (this.assetSearchText) {
      if (this.assetSearchText.length > 0) {
        let tmpArray = [];
        this.markersReady.forEach((value: MarkerWithLabel) => {
          // console.log("Reference - "+value.data.tracker.reference+" - "+value.data.tracker.reference.toLowerCase().includes(this.assetSearchText.toLowerCase()));
          // if(value.data.tracker.nickname){
          //   console.log("Nickname - "+value.data.tracker.nickname+" - "+value.data.tracker.nickname.toLowerCase().includes(this.assetSearchText.toLowerCase()));
          // }
          if (value.data.tracker.reference && value.data.tracker.reference.toLowerCase().includes(this.assetSearchText.toLowerCase())) {
            tmpArray.push(value);
          }
          else if (value.data.tracker.nickname && value.data.tracker.nickname.toLowerCase().includes(this.assetSearchText.toLowerCase())) {
            tmpArray.push(value);
          }
        });
        //console.log(tmpArray);
        this.tmpMarkers = tmpArray;
      }
    }
    this.updateTrackers();
  }

  distance(lat1, lon1, lat2, lon2, unit) {
    if ((lat1 == lat2) && (lon1 == lon2)) {
      return 0;
    }
    else {
      var radlat1 = Math.PI * lat1 / 180;
      var radlat2 = Math.PI * lat2 / 180;
      var theta = lon1 - lon2;
      var radtheta = Math.PI * theta / 180;
      var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180 / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit == "M") {
        dist = (dist * 1.609344 * 1000);
      }
      if (unit == "K") {
        dist = dist * 1.609344
      }
      if (unit == "N") {
        dist = dist * 0.8684
      }
      return dist;
    }
  }

  createRadiusMarker(latitude, longitude) {
    var MarkerWithLabel = require('../../../../node_modules/markerwithlabel')(google.maps);
    let blipChosen = "../../../assets/img/location-icon.png";
    let __this = this;
    this.radiusMarker = new MarkerWithLabel({
      icon: { url: blipChosen, rotation: 45 },
      position: { lat: latitude, lng: longitude },
      //position: new google.maps.LatLng(latitude, longitude),
      draggable: true,
      map: this.map,
      raiseOnDrag: true,
      // labelContent: "1KM Radius",
      // labelAnchor: new google.maps.Point(-16, -18),
      // labelClass: "map-marker-label",
      labelInBackground: false,
    });

    this.markerCircle = new google.maps.Circle({
      map: this.map,
      radius: 1000,
      fillColor: '#00959b',
      fillOpacity: 0.3,
      strokeWeight: 2,
      draggable: true,
      strokeColor: '#ba045d',
    });
    this.markerCircle.bindTo('center', this.radiusMarker, 'position');
    __this.generateAssetsInRadius();
    google.maps.event.addListener(this.radiusMarker, 'dragend', function (event) {
      __this.generateAssetsInRadius();
    });
    google.maps.event.addListener(this.markerCircle, 'dragend', function (event) {
      __this.generateAssetsInRadius();
    });
  }

  checkClickedMarkers(trackedAsset) {
    let assetId: TrackedAsset = trackedAsset;
    let exists = this.collapsedTracker;
    if (exists) {
      if (exists.tracker == assetId.tracker) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  updateTrackers() {
    this.ids = [];
    let __this = this;
    if(!this.markers){
      return;
    }
    //this.updateCluster();
    this.markers.forEach((value: MarkerWithLabel) => {
      if (value.data.tracker.reference && value.data.tracker.reference.toLowerCase().includes((this.assetSearchText ? this.assetSearchText : "").toLowerCase())) {

      }
      else if (value.data.tracker.nickname && value.data.tracker.nickname.toLowerCase().includes((this.assetSearchText ? this.assetSearchText : "").toLowerCase())) {

      }
      else {
        __this.ids.push(value.data.tracker.tracker);
      }
    });

    var MarkerWithLabel = require('../../../../node_modules/markerwithlabel')(google.maps);
    this.markers.forEach((value: MarkerWithLabel) => {
      var symbol = {
        path: value.data.tracker.properties.ignition ? "M2.63,24 14.07,15.99 25.37,24 14.02,0 2.63,24" : "",
        fillColor: "#ba045d",
        strokeColor: "#ba045d",
        fillOpacity: __this.ids.includes(value.data.tracker.tracker) ? 0.3 : 1.0,
        strokeOpacity: __this.ids.includes(value.data.tracker.tracker) ? 0.3 : 1.0,
        scale: 1,
        rotation: value.data.tracker.gps.angle,
        anchor: new google.maps.Point(13, 12),
        origin: new google.maps.Point(12, 12)
      };


      if (value.data.tracker.properties.ignition) {
      }


      let ignitionTemplate = (value.data.tracker.properties.ignition ? "<div class='map-label-section'><span class='on'></span><p class='map-asset-reference'>" + value.data.tracker['reference'] + "</p><br/><p class='map-asset-nickname'>" + value.data.tracker['nickname'] + "</p></div>" : "<div class='map-label-section " + "'><span>&bull;</span><p class='map-asset-reference'>" + value.data.tracker['reference'] + "</p><br/><p class='map-asset-nickname'>" + value.data.tracker['nickname'] + "</p></div>");
      let altInginitionTemplate = (value.data.tracker.properties.ignition ? "<div class='map-label-section'><span class='on'></span><p class='map-asset-reference'>" + value.data.tracker['reference'] + "</p></div>" : "<div class='map-label-section " + "'><span>&bull;</span><p class='map-asset-reference'>" + value.data.tracker['reference'] + "</p></div>");
      let labelContent = value.data.tracker['nickname'] ? ignitionTemplate : altInginitionTemplate;
      value.labelContent = labelContent;

    });

    if (__this.closest) {
      __this.markers.forEach((value: MarkerWithLabel) => {
        if (value.data.tracker.tracker != __this.closest.tracker.tracker) {
          value.setMap(null);
          value.setVisible(false);
        }
        else {
          value.setMap(__this.map);
          value.setVisible(true);
          __this.closest = value.data;
        }
      });
    }
    if (__this.radiusMarker) {
      __this.generateAssetsInRadius();
    }
  }

  constructor(
    private http: HttpClient
  ) {

  }

  getAddressLocations(address) {
    return this.http.get("https://nominatim.codeblack.mt/?", { params: { q: address, format: 'json' } })
  }


}