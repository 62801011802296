// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
	production: false,
	baseUrl: "https://api.waypoint.codeblack.mt/v1",
	ws_url: "https://socket.track.waypoint.codeblack.mt",
	map_url: "https://maps.google.com/maps/api/js?libraries=visualization,places,drawing&key=AIzaSyCDvOZTRv0cAPm3-_Jq2OA8J_0R6wEG_-s",
	per_page : 25,
	version: "1.1",
	appVersion: require('../../package.json').version
  };
  