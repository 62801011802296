import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../../../shared/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from '../../components/message/message.service';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';

@Component({
  selector: 'layout-blank-simplywhite',
  templateUrl: './blank-simplywhite.component.html',
  styleUrls: ['./blank-simplywhite.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BlankSimplywhiteComponent implements OnInit {

  constructor(
    public authService : AuthService,
    public router : Router,
    public messageService : MessageService,
    public activatedRoute : ActivatedRoute,
    public errorService : ErrorHandlingService
  ) { }

  ngOnInit() {
  }

}
