import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule,  HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpModule} from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { CondensedComponent, BlankComponent, RootLayout,CorporateLayout,SimplyWhiteLayout,ExecutiveLayout, CasualLayout } from '../app/@pages/layouts';
import { pagesToggleService } from '../app/@pages/services/toggler.service';
import { SidebarComponent } from '../app/@pages/components/sidebar/sidebar.component';
import { QuickviewComponent } from '../app/@pages/components/quickview/quickview.component';
import { QuickviewService } from '../app/@pages/components/quickview/quickview.service';
import { SearchOverlayComponent } from '../app/@pages/components/search-overlay/search-overlay.component';
import { HeaderComponent } from '../app/@pages/components/header/header.component';
import { HorizontalMenuComponent } from '../app/@pages/components/horizontal-menu/horizontal-menu.component';
import { SharedModule } from '../app/@pages/components/shared.module';
import { pgListViewModule} from '../app/@pages/components/list-view/list-view.module';
import { pgCardModule} from '../app/@pages/components/card/card.module';
import { pgCardSocialModule} from '../app/@pages/components/card-social/card-social.module';
import {BsDropdownModule,AccordionModule,AlertModule,ButtonsModule,CollapseModule,ModalModule,ProgressbarModule,TabsModule,TooltipModule,TypeaheadModule} from 'ngx-bootstrap';
import { pgTabsModule } from '../app/@pages/components/tabs/tabs.module';
import { pgSwitchModule } from '../app/@pages/components/switch/switch.module';
import { ProgressModule } from '../app/@pages/components/progress/progress.module';
import { QuillModule } from 'ngx-quill';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { BlankCorporateComponent } from '../app/@pages/layouts/blank-corporate/blank-corporate.component';
import { BlankCasualComponent } from '../app/@pages/layouts/blank-casual/blank-casual.component';
import { NotificationsComponent } from './view/notifications/notifications.component';
import { AppRoutingModule } from './app.routing';
import { UserComponent } from './view/user/user.component';
import { QuickviewAlertsComponent } from './view/alerts/quickview/quickview.component';
import { JwtInterceptor } from './shared/interceptors/jwt.interceptor';
import { ErrorInterceptor } from './shared/interceptors/error.interceptor';
import { EncodeHttpParamsInterceptor } from './shared/interceptors/http.interceptor';
import { LoginComponent } from './view/session/login/login.component';
import { ForgotComponent } from './view/session/forgot-password/forgot.component';
import { RoleService } from './shared/guards/role.service';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { pgSelectModule } from './@pages/components/select/select.module';
import { WorkerService } from './shared/services/worker.service';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

export class AppHammerConfig extends HammerGestureConfig  {
  overrides = <any>{'pinch': { enable: false },'rotate': { enable: false }
  }
}

@NgModule({
  declarations: [
    AppComponent,
    NotificationsComponent,
    CondensedComponent,
    CorporateLayout,
    SimplyWhiteLayout,
    ExecutiveLayout,
    CasualLayout,QuickviewAlertsComponent,
    SidebarComponent, QuickviewComponent, SearchOverlayComponent, HeaderComponent,HorizontalMenuComponent,
    BlankComponent,
    RootLayout,
    BlankCorporateComponent,
    BlankCasualComponent,
    UserComponent,
    LoginComponent,
    ForgotComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    SharedModule,
    ProgressModule,
    pgListViewModule,
    pgCardModule,
    pgCardSocialModule,
    pgSelectModule,
    BsDropdownModule.forRoot(),
    AccordionModule.forRoot(),
    AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    ProgressbarModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
    pgTabsModule,
    PerfectScrollbarModule,
    pgSwitchModule,
    QuillModule,
    AppRoutingModule,
    ProgressModule,
    GooglePlaceModule
  ],
  providers: [
    RoleService,
    WorkerService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: EncodeHttpParamsInterceptor, multi: true},
    QuickviewService,pagesToggleService,{
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: AppHammerConfig
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }