import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { BlankSimplywhiteComponent, SimplyWhiteLayout } from './@pages/layouts';
import { AppComponent } from './app.component';
import { LoginComponent } from './view/session/login/login.component';
import { SessionModule } from './view/session/session.module';
import { ForgotComponent } from './view/session/forgot-password/forgot.component';
import { AuthGaurd } from './shared/guards/auth-guard.service';
import { RoleGuard } from './shared/guards/role-guard';
import { SettingsComponent } from './view/settings/settings.component';


const AppRoutes: Routes = [

  {
    path: '',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Home'
    },
    loadChildren: './view/dashboard/dashboard.module#DashboardModule'
  },
  {
    path: 'dashboard',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Dashboard'
    },
    loadChildren: './view/dashboard/dashboard.module#DashboardModule'
  },
  {
    path: 'tracking',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Tracking'
    },
    loadChildren: './view/tracking/tracking.module#TrackingModule'
  },
  {
    path: 'settings',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Settings'
    },
    loadChildren: './view/settings/settings.module#SettingsModule'
  },
  {
    path: 'diagnostics',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Diagnostics'
    },
    loadChildren: './view/diagnostics/diagnostics.module#DiagnosticsModule'
  },
  {
    path: 'alerts',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Alerts'
    },
    loadChildren: './view/alerts/alerts.module#AlertsModule'
  },
  {
    path: 'support',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Support'
    },
    loadChildren: './view/support/support.module#SupportModule'
  },
  {
    path: 'user',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'User'
    },
    loadChildren: './view/user/user.module#UserModule'
  },
  {
    path: 'modification',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Modification'
    },
    loadChildren: './view/modification/modification.module#ModificationModule'
  },
  // {
  //   path: 'session',
  //   data: {
  //       breadcrumb: 'Sessions'
  //   },
  //   loadChildren: './view/session/session.module#SessionModule'
  // },
  {
    path: 'reports',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Reports'
    },
    loadChildren: './view/reports/reports.module#ReportsModule'
  },
  {
    path: 'geofencing',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Geofencing'
    },
    loadChildren: './view/geofencing/geofencing.module#GeofencingModule'
  },
  {
    path: 'session/login',
    component: LoginComponent,
    data: {
        breadcrumb: 'Sessions'
    }
  },
  {
    path: 'session/forgot',
    component: ForgotComponent,
    data: {
        breadcrumb: 'Sessions'
    }
  },
  // {
  //   path: 'settings',
  //   component: SettingsComponent,
  //   data: {
  //       breadcrumb: 'Sessions'
  //   }
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(AppRoutes, { useHash: true })],
  exports: [RouterModule, SessionModule],
  providers: [AuthGaurd, RoleGuard]
  //providers: [AuthGaurd, RoleGuard]
})
export class AppRoutingModule { }