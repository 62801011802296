import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SessionRoute } from './session.routing';

import { SharedModule } from '../../@pages/components/shared.module';
import { ErrorComponent } from './error/error.component';
import { LockscreenComponent } from './lockscreen/lockscreen.component';
import { RegisterPageComponent } from './register/register.component';
import { BlankSimplywhiteComponent } from '../../@pages/layouts';
import { MessageService } from '../../@pages/components/message/message.service';
import { MessageModule } from '../../@pages/components/message/message.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule, 
    SharedModule,
    MessageModule,
    RouterModule.forChild(SessionRoute),
  ],
  declarations: [ErrorComponent,LockscreenComponent, RegisterPageComponent, BlankSimplywhiteComponent],
  providers: [MessageService]
})
export class SessionModule { }
