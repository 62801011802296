import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { RootLayout } from '../app/@pages/layouts';
import { MarkerWithLabel } from "../../node_modules/markerwithlabel";
import { MenuService } from './shared/services/menu.service';
import { AuthService } from './shared/services/auth.service';
import * as moment from 'moment';
import { TrackedAsset } from './shared/models/tracked-asset.model';
import * as $ from 'jquery';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { environment } from '../environments/environment';
import { Observable, Subscription } from 'rxjs';
import { WORKER_TOPIC } from '../worker/app-workers/shared/worker-topic.constants';
import { WorkerMessage } from '../worker/app-workers/shared/worker-message.model';
import { isValid } from 'date-fns';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['../app/@pages/layouts/simplywhite/simplywhite.component.scss', './app.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AppComponent extends RootLayout implements OnInit {

  showOnlineCars: boolean = false;
  isRadiusOn = true;
  zoomLevel = 12;
  footerHide: string[] = ["/session/login", "/tracking"];
  _boxed = true;
  isAlert = false;
  iterations = 0;
  workerTopic = WORKER_TOPIC.assetData;
  workerServiceSubscription: Subscription;
  runCheck() {
    let __this = this;
    this.getJSON().subscribe(data => {
      let latestVersion = data['version'];
      this.versionNumber = environment.appVersion;
      if (!__this.warned && latestVersion != environment.appVersion) {
        __this.warned = true;
      }
    });
  }

  checkIndex(number) {
    this.mapService.sideMenuPageIndex = number;
    if (number == 2) {
      this.addRadiusMarker();
    }
    else {
      this.clearLiveSearch();
    }
  }

  getIndex() {
    let val = this.mapService.sideMenuPageIndex;
    return val;
  }


  ngOnInit() {
    let __this = this;
    this.runCheck();
    this.loadAssets();

    setInterval(() => {
      __this.runCheck();
    }, 1000 * 60 * 60);

    this.router.events.subscribe(res => {
      //console.log("Route: ",res);
      if (res['url']) {
        let fullUrl = res['url'].toString();
        if (res['url'].toString().includes("forgot")) {
          this.authService.isForgotPassword = true;
        }
        if (!res['url'].toString().includes("alerts")) {
          this.isAlert = true;
        }
        else {
          if (res['url'].toString().includes("modification")) {
            this.isAlert = true;
          }
          else {
            this.isAlert = false;
          }
        }


        if (res['url'].toString().includes("alerts-rules")) {
          this.isAlert = false;
        }
        if (!res['url'].toString().includes("tracking")) {
          this.mapService.viewOpen = false;
          this.mapService.viewAssets = false;
          this.mapService.closestList = new Map<number, any>();
          this.mapService.radiusMarker = null;
          this.mapService.markerCircle = null;
          this.mapService.assetSearchText = null;
          this.mapService.closest = null;
          this.liveSearch = null;
          this.newLat = null;
          this.newLng = null;
          this.addresses = null;
          if (this.timeout) {
            clearTimeout(this.timeout);
          }
          this.timeout = null;
          return;
        }
        else {
          this.mapService.map = null;
          this.mapService.viewOpen = true;
          this.mapService.viewAssets = true;
        }
        if (!res['url'].toString().includes("login") && !this.authService.authenticated) {
          this.router.navigate(['session/login']);
          return;
        }
        // console.log(fullUrl);
        // console.log(this.footerHide.includes(fullUrl));
        if (this.footerHide.includes(fullUrl)) {
          this.toggler.toggleFooter(false);
        }
        else {
          this.toggler.toggleFooter(true);
        }
        // $('body').pagescalendar({
        //   ui: {
        //       week: {
        //           startOfTheWeek: '1',
        //           endOfTheWeek:'0'
        //       }
        //     }
        //   });
      }
    });
    this.changeLayout("menu-pin");
    //Will sidebar close on screens below 1024
    this.populateLinks();
    this.autoHideMenuPin();
  }

  getHumanTime(asset: TrackedAsset) {
    if (!asset.gps) {
      return "Loading";
    }
    //console.log(asset.gps.timestamp);
    return moment.duration(moment(new Date()).diff(asset.gps.timestamp)).humanize() + " ago";
  }

  focusMarker(event) {
    // for(let t of this.mapService.latestAssets){
    //   t.active = false;
    //   if(t.tracker == event.tracker){
    //     t.active = true;
    //     this.showLocation(t, true);
    //   }
    // }
  }

  assetToggle(trackedAsset) {
    //trackedAsset.active = true;
    for (let t of this.mapService.trackedAssets) {
      if (!trackedAsset.active && t.tracker == trackedAsset.tracker) {
        if (t['data']) {
          t.active = true;
          this.mapService.map.panTo({ lat: t['data']['tracker']['gps'].latitude, lng: t['data']['tracker']['gps'].longitude });
        }
      }
      else {
        t.active = false;
      }
    }
    for (let t of this.mapService.latestAssets) {
      if (!trackedAsset.active && t.tracker == trackedAsset.tracker) {
        if (t['data']) {
          t.active = true;
          this.mapService.map.panTo({ lat: t['data']['tracker']['gps'].latitude, lng: t['data']['tracker']['gps'].longitude });
        }
      }
      else {
        t.active = false;
      }
    }
  }


  triggered:boolean = false;
  timeoutAlt:any;
  getLatestAssets() {
    let __this = this;
    if(__this.mapService.markForCheck){
      if(!__this.timeoutAlt){
        __this.timeoutAlt = setTimeout(() => {
          __this.cRef.detectChanges();
          __this.loadAssetsData();
          console.log("Reorganizing!");
          __this.mapService.markForCheck = false;
          __this.timeoutAlt = null;
        }, 500);
      }
    }
    if(!this.mapService.latestAssets && this.mapService.trackedAssets && this.mapService.markersReady && !this.triggered){
      __this.loadAssetsData();
    }
    return this.mapService.latestAssets;
  }

  loadAssetsData() {
    let __this = this;
    if (this.mapService.markersReady && this.mapService.markersReady.length > 0) {
      __this.triggered = true;
      let markersReady = [];
      for (let mr of this.mapService.markersReady) {
        markersReady.push(mr.data);
      }
      
      const workerMessage = new WorkerMessage(this.workerTopic, { trackedAssets: this.mapService.trackedAssets, markersReady: markersReady });
      this.workerService.doWork(workerMessage);

      this.workerServiceSubscription = this.workerService.workerUpdate$.subscribe(data => {
        if (data.topic === this.workerTopic) {
          for (let d of data.data.orderedArray) {
            if(d.data){
              if (isValid(new Date(d.data.tracker.gps.timestamp))) {
                d.data.tracker.gps.timestamp = moment.duration(moment(new Date()).diff(d.data.tracker.gps.timestamp)).humanize() + " ago";
              }
            }
          }
          if (__this.mapService.latestAssets) {
            for (let oldAsset of __this.mapService.latestAssets) {
              for (let newAsset of data.data.orderedArray) {
                if (newAsset.tracker == oldAsset.tracker && oldAsset.active) {
                  newAsset.active = true;
                }
              }
            }
            __this.mapService.latestAssets = data.data.orderedArray;
          }
          else {
            __this.mapService.latestAssets = data.data.orderedArray;
          }
        }
      });
    }
  }

  changeRadius(radius) {
    this.mapService.closest = null;
    this.liveSearch = null;
    this.newLat = null;
    this.newLng = null;
    this.addresses = null;
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    if (!this.mapService.radiusMarker) {
      return;
    }
    let __this = this;
    this.mapService.markerCircle.setMap(null);
    this.mapService.markerCircle = new google.maps.Circle({
      map: this.mapService.map,
      radius: radius,
      fillColor: '#00959b',
      fillOpacity: 0.3,
      strokeWeight: 2,
      draggable: true,
      strokeColor: '#ba045d',
    });

    this.mapService.radiusMarker.setOptions({
      icon: this.mapService.radiusMarker.icon,
      position: this.mapService.radiusMarker.getPosition(),
      //position: new google.maps.LatLng(latitude, longitude),
      draggable: true,
      map: this.mapService.map,
      raiseOnDrag: true,
      // labelContent: (this.mapService.markerCircle.radius / 1000)+"KM",
      // labelAnchor: new google.maps.Point(-16, -18),
      //labelClass: "map-marker-label",
      labelInBackground: false,
    })

    this.mapService.markerCircle.bindTo('center', this.mapService.radiusMarker, 'position');
    __this.mapService.generateAssetsInRadius();
    google.maps.event.addListener(this.mapService.markerCircle, 'dragend', function (event) {
      __this.mapService.generateAssetsInRadius();
      __this.mapService.updateCluster();
    });
    this.mapService.updateCluster();
  }

  switchRadius(state) {
    if (this.isRadiusOn != state) {
      this.clearLiveSearch();
    }
    if (this.isRadiusOn != state && state == true) {
      this.addRadiusMarker();
    }
    this.isRadiusOn = state;
  }

  isRadius(radius): boolean {
    if (this.mapService.markerCircle) {
      if (this.mapService.markerCircle.radius == radius) {
        return true;
      }
    }
    return false;
  }

  radiusEnabled() {
    return this.mapService.radiusMarker;
  }

  notAlerts() {
    return this.isAlert;
  }

  isForgotPassword() {
    return this.authService.isForgotPassword;
  }

  interval:any;
  loadAssets() {
    let __this = this;
    this.interval = setInterval(() => {
      if(__this.authService.getUserId()){
        __this.userService.getUser(__this.authService.getUserId()).subscribe(res => {
          __this.mapService.trackedAssets = res['data']['fleet'];
          for (let t of res['data']['fleet']) {
            __this.mapService.trackedAssetsIds.push(t['id']);
          }
          console.log(__this.mapService.trackedAssets);
        },
          err => {
            console.log(err);
          });
          clearInterval(__this.interval);
      }
    }, 1000);
  }

  getMarkers() {
    let __this = this;
    if (this.mapService) {
      if (this.mapService.markersReady) {
        return this.mapService.markersReady.filter(val => {
          if (__this.mapService.trackedAssetsIds.includes(val.data.tracker)) {
            return false;
          }
          else {
            return true;
          }
        });
      }
    }
    return null;
    //return this.mapService.markersReady;
  }

  @ViewChild("placesRef") placesRef: GooglePlaceDirective;
  liveSearch: any;
  newLat: number;
  newLng: number;
  addresses: any[];
  timeout: any;
  handleAddressChange(address) {
    let __this = this;
    this.liveSearch = address.formatted_address;
    this.mapService.closest = null;
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.mapService.getAddressLocations(address).subscribe(res => {
        __this.addresses = JSON.parse(JSON.stringify(res));
      },
        err => {
          console.log(err);
        });
    }, 350);

    console.log(address);
  }

  isOff(closest): boolean {
    if (closest) {
      if (this.getClosest().tracker.is_online) {
        return false;
      }
    }
    return true;
  }

  isOffList(closest): boolean {
    if (closest) {
      if (closest.tracker.is_online) {
        return false;
      }
    }
    return true;
  }

  getClosest() {
    return this.mapService.closest;
  }

  clearLiveSearch(initialize?) {
    let __this = this;
    this.liveSearch = null;
    this.mapService.closest = null;
    this.mapService.updateTrackers();
    if (this.mapService.radiusMarker) {
      this.mapService.radiusMarker.setMap(null);
    }
    if (this.mapService.markerCircle) {
      this.mapService.markerCircle.setMap(null);
    }
    this.mapService.markers.forEach((m: MarkerWithLabel) => {
      m.setMap(__this.mapService.map);
    });
    this.mapService.radiusMarker = null;
    this.mapService.markerCircle = null;
    this.mapService.updateTrackers();
    this.mapService.closestList = new Map<number, any>();
    if (initialize) {
      this.addRadiusMarker();
    }
    else {
      this.mapService.markers.forEach((m: MarkerWithLabel) => {
        m.setMap(__this.mapService.map);
        m.setVisible(true);
      });
    }
    this.mapService.updateCluster();
  }

  getDistance() {
    return this.mapService.distance(this.newLat, this.newLng, this.mapService.closest.tracker.gps.latitude, this.mapService.closest.tracker.gps.longitude, 'K');
  }

  loadAddressSearch() {
    this.mapService.closest = null;
    this.mapService.closestList = new Map<number, any>();
    if (this.mapService.radiusMarker) {
      this.mapService.radiusMarker.setMap(null);
      this.mapService.markerCircle.setMap(null);
    }
    this.mapService.radiusMarker = null;
    this.mapService.markerCircle = null;
    if (this.mapService.markers) {
      this.mapService.markers.forEach((m: MarkerWithLabel) => {
        let oldLat = m.data.tracker.gps.latitude;
        let oldLng = m.data.tracker.gps.longitude;
        if (this.mapService.closest != null) {
          if (this.mapService.distance(this.newLat, this.newLng, oldLat, oldLng, 'K') < this.mapService.distance(this.newLat, this.newLng, this.mapService.closest.tracker.gps.latitude, this.mapService.closest.tracker.gps.longitude, 'K')) {
            this.mapService.closest = m.data;
          }
        }
        else {
          this.mapService.closest = m.data;
        }
      });
    }
    if (this.mapService.closest != null) {
      console.log("Closest: ", this.mapService.closest);
    }
    this.mapService.markers.forEach((m: MarkerWithLabel) => {
      if (m.data.tracker.tracker != this.mapService.closest.tracker.tracker) {
        m.setMap(null);
      }
      else {
        m.setMap(this.mapService.map);
      }
    });
  }

  updatePage() {
    location.reload();
  }

  liveSearchDisable() {
    if (this.mapService.trackedAssets) {
      return false;
    }
    return true;
  }

  search() {
    this.newLat = this.liveSearch.lat;
    this.newLng = this.liveSearch.lon;
  }

  searchRadius() {
    let latLng = { lat: +this.liveSearch.lat, lng: +this.liveSearch.lon };
    this.mapService.radiusMarker.setPosition(latLng);
    this.mapService.generateAssetsInRadius();
  }

  setClicked(marker, $event) {
    // let assetId : TrackedAsset = marker.data.tracker;
    // if(this.checkClickedMarkers(marker)){
    //   this.mapService.collapsedTracker = null;
    // }
    // else{
    //   this.mapService.collapsedTracker = assetId;
    // }
  }

  locAnim: any;
  showLocation(asset, skipZoom?) {
    let __this = this;
    if (!skipZoom) {
      this.zoomLevel = 19;
      __this.mapService.smoothZoom(__this.mapService.map, 19, __this.mapService.map.getZoom(), true);
      this.mapService.map.panTo({ lat: asset['gps'].latitude, lng: asset['gps'].longitude });
    }

    if (skipZoom) {
      this.mapService.map.panTo({ lat: asset['data']['tracker']['gps'].latitude, lng: asset['data']['tracker']['gps'].longitude });
    }

    if (this.locAnim) {
      clearTimeout(this.locAnim);
    }

    if (!skipZoom) {
      this.locAnim = setTimeout(() => {
        for (let m of __this.mapService.markersReady) {
          if (m.data.tracker.tracker == asset.tracker) {
            let currentOpacity = 0.1;
            let markerCircle = new google.maps.Circle({
              map: __this.mapService.map,
              radius: skipZoom ? 500 : 50,
              fillColor: '#00959b',
              fillOpacity: currentOpacity,
              strokeWeight: 2,
              draggable: false,
              strokeColor: '#ba045d',
            });

            let maxOpacity = 0.6;
            let minOpacity = 0.1;
            let reachedMax = false;
            var circleTimer = setInterval(function () {

              if (!reachedMax) {
                currentOpacity += skipZoom ? 0.075 : 0.025;
              }
              else {
                currentOpacity -= skipZoom ? 0.075 : 0.025;
              }

              if (!reachedMax && currentOpacity > maxOpacity) {
                reachedMax = true;
              }

              if (currentOpacity < minOpacity) {
                clearInterval(circleTimer);
                markerCircle.setMap(null);
                return;
              }

              markerCircle.setOptions({
                map: __this.mapService.map,
                radius: skipZoom ? 500 : 50,
                fillColor: '#00959b',
                fillOpacity: currentOpacity,
                strokeWeight: 2,
                draggable: false,
                strokeColor: '#ba045d',
              });
              for (let mN of __this.mapService.markersReady) {
                if (mN.data.tracker.tracker == m.data.tracker.tracker) {
                  markerCircle.setCenter(mN.getPosition());
                  break;
                }
              }
            }, 50);

            markerCircle.bindTo('center', m, 'position');

            setTimeout(() => {
              markerCircle.setMap(null);
            }, 1000);

            //m.labelContent = "<div id='" + id + "' class='map-label-section'><span class='semi-transparent-circle2'></span><img id='" + id + "' src='../../../../assets/img/wp-stop-icon.svg' class='map-pin-stop'></div>";
            m.labelContent = m.data.tracker['nickname'] ?
              (m.data.tracker.properties.ignition ? "<div class='map-label-section'><span class='on'></span><p class='map-asset-reference'>" + m.data.tracker['reference'] + "</p><br/><p class='map-asset-nickname'>" + m.data.tracker['nickname'] + "</p></div>" : "<div class='map-label-section'><span>&bull;</span><p class='map-asset-reference'>" + m.data.tracker['reference'] + "</p><br/><p class='map-asset-nickname'>" + m.data.tracker['nickname'] + "</p></div>")
              : (m.data.tracker.properties.ignition ? "<div class='map-label-section'><span class='on'></span><p class='map-asset-reference'>" + m.data.tracker['reference'] + "</p></div>" : "<div class='map-label-section'><span>&bull;</span><p class='map-asset-reference'>" + m.data.tracker['reference'] + "</p></div>");
          }
        }
      }, 1500);
    }
  }

  isActive(tracker): boolean {
    if (tracker.active) {
      //console.log(tracker);
      return true;
    }
    return false;
  }

  // markerCircle: any;
  // circleTimer: any;
  toggleFollow(tracker) {
    if (this.mapService.markerCircle) {
      this.mapService.markerCircle.setMap(null);
      clearInterval(this.mapService.circleTimer);
    }
    if (this.mapService.followedAssetID == tracker['tracker']) {
      this.mapService.followedAsset = null;
      this.mapService.followedAssetID = null;
      clearInterval(this.mapService.circleTimer);
      if (this.mapService.markerCircle) {
        this.mapService.markerCircle.setMap(null);
      }
      return;
    }
    this.zoomLevel = 17;
    this.mapService.smoothZoom(this.mapService.map, 17, this.mapService.map.getZoom(), true);
    this.followBlip();
    this.mapService.map.panTo({ lat: tracker['gps']['latitude'], lng: tracker['gps']['longitude'] });
    this.mapService.followedAsset = tracker;
    this.mapService.followedAssetID = tracker['tracker'];
  }

  followBlip() {
    let modifier = 2500;
    let zoom = (this.mapService.map.zoom);
    let currentRadius = Math.floor(zoom + modifier * Math.pow(2, -zoom / 7.));
    this.mapService.markerCircle = new google.maps.Circle({
      map: this.mapService.map,
      radius: currentRadius,
      strokeWeight: 2,
      draggable: false,
      strokeColor: '#ba045d80',
    });

    let maxRadius = 0;
    let minRadius = 0;

    let reachedMax = false;
    let __this = this;

    if (currentRadius > maxRadius || currentRadius < minRadius) {
      currentRadius = minRadius;
    }

    this.mapService.circleTimer = setInterval(function () {

      if(!__this.mapService.map){
        clearInterval(__this.mapService.circleTimer);
        return;
      }

      zoom = (__this.mapService.map.zoom);
      modifier = (10000 - (zoom * 500));

      if (zoom <= 12) {
        modifier += 500;
      }

      let isClustered = false;
      //__this.mapService.markerCluster.repaint();
      const clusters = __this.mapService.markerCluster.getClusters();
      for (let i = 0, l = clusters.length; i < l; i++) {
        const markers = clusters[i].getMarkers();
        for (const m of markers) {
          if (m['data']['tracker']['tracker'] === __this.mapService.followedAsset.tracker && markers.length > 1) {
            isClustered = true;
          }
        }
      }

      if (__this.mapService.followedAssetID && zoom <= 10 || isClustered) {
        if (__this.mapService.markerCircle) {
          __this.mapService.markerCircle.setVisible(false);
        }
        return;
      }
      else {
        if (__this.mapService.markerCircle) {
          __this.mapService.markerCircle.setVisible(true);
        }
      }

      maxRadius = Math.floor(zoom + modifier * Math.pow(2, -zoom / 7.)) * 1.10;
      minRadius = Math.floor(zoom + modifier * Math.pow(2, -zoom / 7.)) * 0.90;

      if (currentRadius < minRadius) {
        currentRadius = minRadius;
      }

      if (!reachedMax) {
        currentRadius += (zoom >= 18 ? 0.5 : 5);
      }
      else {
        currentRadius -= (zoom >= 18 ? 0.5 : 5);
      }

      if (!reachedMax && currentRadius > maxRadius) {
        currentRadius = maxRadius;
        reachedMax = true;
      }

      if (currentRadius < minRadius) {
        currentRadius = minRadius;
        reachedMax = false;
      }

      if (currentRadius > maxRadius){
        currentRadius = maxRadius;
      }

      __this.mapService.markerCircle.setOptions({
        map: __this.mapService.map,
        radius: currentRadius,
        fillColor: '#00959b',
        fillOpacity: 0,
        strokeWeight: 2,
        draggable: false,
        strokeColor: '#ba045d80',
      });
      for (let mN of __this.mapService.markersReady) {
        if (mN.data.tracker.tracker == __this.mapService.followedAssetID) {
          __this.mapService.markerCircle.setCenter(mN.getPosition());
          __this.mapService.markerCircle.bindTo('center', mN, 'position');
          break;
        }
      }
    }, (80));
  }

  getAuthService(): AuthService {
    return this.authService;
  }

  addRadiusMarker() {
    if (this.mapService.radiusMarker) {
      this.mapService.radiusMarker.setMap(null);
      this.mapService.markerCircle.setMap(null);
      this.mapService.radiusMarker = null;
      this.mapService.markerCircle = null;
      this.mapService.updateTrackers();
    }
    else {
      this.mapService.createRadiusMarker(35.890591, 14.444105);
    }
    this.mapService.updateCluster();
  }

  getClosestList() {
    let array = Array.from(this.mapService.closestList.values());
    let tmp = [];
    for (let chk of array) {
      if (chk.map == null) {
        continue;
      }
      tmp.push(chk.data);
    }
    if (tmp.length == 0) {
      return null;
    }
    return tmp;
  }

  setActiveTab(tab) {
    for (let t of this.getClosestList()) {
      t.tracker['active_tab'] = false;
    }
    tab['active_tab'] = true;
  }

  getDistanceList(closest) {
    if (!this.mapService.radiusMarker) {
      return null;
    }
    return this.mapService.distance(this.mapService.radiusMarker.position.lat(), this.mapService.radiusMarker.position.lng(), closest.tracker.gps.latitude, closest.tracker.gps.longitude, 'K');
  }

  showHistory(tracker) {
    this.router.navigate(['reports/trip-reports/' + tracker]);
  }

  getMapService() {
    return this.mapService;
  }

  getService(): MenuService {
    return this.menuService;
  }

  toggleView() {
    this.mapService.viewOpen = (this.mapService.viewOpen == true ? false : true);
  }

  getView() {
    return this.mapService.viewOpen;
  }

  viewAssets() {
    return this.mapService.viewAssets;
  }

  isAuthenticated(): boolean {
    return this.authService.authenticated;
  }

  populateLinks() {
    this.menuService.populateLinks(this.notificationService, this.authService);
  }

  openCodeblack() {
    window.open("https://codeblack.mt");
  }
}