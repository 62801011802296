import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Alert } from '../models/alert.model';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationService  {

    constructor(
        private http: HttpClient) {
    }

    getAll(params) {
        return this.http.get(`${environment.baseUrl}/alert/notification`,{params:params});
    }

    get(id: number) {
        return this.http.get(`${environment.baseUrl}/alert/notification/` + id);
    }

    delete(id: number) {
        return this.http.delete(`${environment.baseUrl}/alert/notification/` + id);
    }

    create(formData) {
        return this.http.post(`${environment.baseUrl}/alert/notification`, formData);
    }

    update(id: number, assetData) {
        console.log(assetData);
        return this.http.put(`${environment.baseUrl}/alert/notification/` + id, assetData);
    }

}
