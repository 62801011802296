import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../shared/services/auth.service';
import { Router } from '@angular/router';
import { BlankSimplywhiteComponent } from '../../../@pages/layouts';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BlankSimplywhiteComponent implements OnInit {
  //Sample Varriables
  userName;
  password;
  wrongPassword = false;
  errors : any[];
  // constructor(
  //   private authService : AuthService,
  //   private router : Router
  // ) { }

  ngOnInit() {
    if(this.authService.authenticated){
      this.router.navigate(['tracking']);
    }
  }

  forgot(){
    this.authService.isForgotPassword = true;
  }

  login(){
    let __this = this;
    this.authService.signin({email:this.userName,password:this.password}).subscribe(res => {
      if(this.authService.authenticated){
        this.router.navigate(['tracking']);
      }
      __this.wrongPassword = false;
    },
    err => {
      __this.wrongPassword = true;
      this.errors = [];
      this.errors = this.errorService.getErrors(err);
      console.log(err);
    })
  }

}
