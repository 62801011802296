import { Organisation } from "./organisation.model";

export class User{

    id: number;
    first_name: string;
    last_name: string;
    full_name: string;
    email: string;
    system_role: {
      id: number;
      title: string;
    };
    organisation: Organisation[];
    routerLink: string;
    fleet:any[];

    constructor(input?){
        input = input || {};
        this.id = input.id || '';
        this.first_name = input.first_name || '';
        this.last_name = input.last_name || '';
        this.email = input.email || '';
        this.system_role = input.system_role || {};
        this.organisation = input.organisation || {};
        this.routerLink = "modification/user/"+this.id || '';
        this.full_name = this.first_name+" "+this.last_name  || '';
        this.fleet = input.fleet || [];
    }
}