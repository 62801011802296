
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Alert } from '../models/alert.model';
import { NotificationService } from './notification.service';
import { isArray } from 'rxjs/internal/util/isArray';

@Injectable({
    providedIn: 'root'
})
export class MenuService {

    trackedVehicles : number = 0;
    totalAlerts : number = 0;
    viewOpen: boolean = true;
    viewAssets = false;
    menuLinks = [];
    system_roles = {
        admin: 1,
        organisation_owner: 2,
        installer: 3
    };
    menu = [];


    constructor() {
    }

    populateLinks(notificationService : NotificationService, authService: AuthService) {
        let doc = this;
        this.generateLinks(authService);
        if(authService.authenticated){
            notificationService.getAll({}).subscribe(res => {
                let alerts : Alert[] = res['data'];
                if(isArray(alerts)){
                    alerts = alerts.filter(value => !value.is_read);
                  }
                doc.totalAlerts = alerts.length;
                // console.log(doc.totalAlerts);
                doc.generateLinks(authService);
            },
            err => {
                console.log(err);
            });
        }
    }

    generateLinks(authService){
        this.menuLinks = [
            {
                label: "Tracking",
                //details: (this.trackedVehicles > 0 ? this.trackedVehicles+" Vehicles Tracked" : "No Tracked Vehicles"),
                routerLink: "/tracking",
                roles:[this.system_roles.admin,this.system_roles.organisation_owner],
                iconType: "pg pg-map",
                iconName: "mail"
            },
            {
                label: "Reports",
                roles:[this.system_roles.admin,this.system_roles.organisation_owner],
                iconType: "fa fa-newspaper-o",
                iconName: "fa",
                toggle: "close",
                submenu: [
                    {
                        label: "Trip Reports",
                        routerLink: "reports/trip-reports",
                        roles:[this.system_roles.admin,this.system_roles.organisation_owner],
                        iconType: "far fa-map-marker-alt",
                        iconName: "far",
                    },
                    {
                        label: "Trail Reports",
                        routerLink: "reports/trail-reports",
                        roles:[this.system_roles.admin,this.system_roles.organisation_owner],
                        iconType: "far fa-map-marked-alt",
                        iconName: "far",
                    },
                    // {
                    //     label: "Location Reports",
                    //     routerLink: "reports/location-reports",
                    //     roles:[this.system_roles.admin,this.system_roles.organisation_owner],
                    //     iconType: "far fa-history",
                    //     iconName: "far",
                    // },
                ]
            },
            {
                label: "Notifications",
                // details: (this.totalAlerts > 0 ? this.totalAlerts+" Unread Alerts" : "No Alerts"),
                routerLink: "/alerts",
                roles:[this.system_roles.admin,this.system_roles.organisation_owner],//,this.system_roles.organisation_owner
                iconType: "fa fa-exclamation-triangle",
                iconName: "layouts"
            },
            // {
            //     label: "Statistics",
            //     routerLink: "/dashboard",
            //     roles:[this.system_roles.admin,this.system_roles.organisation_owner],
            //     iconType: "fa fa-dashboard",
            //     iconName: "home"
            // },
            {
                label: "Settings",
                routerLink: "/modification",
                roles:[this.system_roles.admin,this.system_roles.organisation_owner,this.system_roles.installer],
                iconType: "pg pg-settings",
                iconName: "settings",
                toggle: "close",
                submenu: [
                    {
                        label: "Assets",
                        routerLink: "/modification/vehicle",
                        roles:[this.system_roles.admin,this.system_roles.organisation_owner,this.system_roles.installer],
                        iconType: "fa fa-car",
                        iconName: "layouts",
                    },
                    {
                        label: "Users",
                        routerLink: "/modification/user",
                        roles:[this.system_roles.admin],
                        iconType: "fa fa-users",
                        iconName: "sl",
                    },
                    {
                        label: "Organisations",
                        routerLink: "/modification/organisation",
                        roles:[this.system_roles.admin],
                        iconType: "fa fa-building",
                        iconName: "bl",
                    },
                    {
                        label: "Trackers",
                        routerLink: "/modification/tracker",
                        roles:[this.system_roles.admin,this.system_roles.installer],
                        iconType: "fa fa-compass",
                        iconName: "bl",
                    },
                    {
                        label: "Alert Rules",
                        routerLink: "/modification/alerts-rules",
                        roles:[this.system_roles.admin,this.system_roles.organisation_owner],
                        iconType: "far fa-exclamation-triangle",
                        iconName: "bl",
                    }
                ]
            },
            // {
            //     label: "Privacy",
            //     roles:[this.system_roles.admin,this.system_roles.organisation_owner],
            //     iconType: "far fa-eye-slash",
            //     iconName: "fa",
            //     toggle: "close",
            //     submenu: [
            //         {
            //             label: "Asset",
            //             routerLink: "/modification/privacy-mode/asset",
            //             roles:[this.system_roles.admin,this.system_roles.organisation_owner],
            //             iconType: "far fa-car",
            //             iconName: "far",
            //         },
            //         {
            //             label: "Organisation",
            //             routerLink: "/modification/privacy-mode/organisation",
            //             roles:[this.system_roles.admin,this.system_roles.organisation_owner],
            //             iconType: "far fa-building",
            //             iconName: "far",
            //         }
            //     ]
            // },
            {
                label: "Support",
                routerLink: "/support",
                roles:[this.system_roles.admin,this.system_roles.organisation_owner],
                iconType: "fa fa-support",
                iconName: "layouts"
            },
        ];
        this.filterMenu(authService);
    }

    filterMenu(authService){
        let role = authService.getUserRole();
        for(let parent = 0; parent < this.menuLinks.length;parent++){
            if(!this.menuLinks[parent].roles.includes(role)){
                this.menuLinks[parent] = null;
            }
            if(this.menuLinks[parent]){
                if(this.menuLinks[parent].submenu){
                    for(let child = 0; child < this.menuLinks[parent].submenu.length;child++){
                        if(!this.menuLinks[parent].submenu[child].roles.includes(role)){
                            this.menuLinks[parent].submenu[child] = null;
                        }
                    }
                    this.menuLinks[parent].submenu = this.menuLinks[parent].submenu.filter(Boolean);
                }
            }
        }
        this.menuLinks = this.menuLinks.filter(Boolean);
    }



}
