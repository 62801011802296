import { Organisation } from "./organisation.model";

export class Tracker {

    id: number;
    imei: number;
    organisation: Organisation;
    is_online: boolean;
    routerLink: string;
    created_by: {
      id: number;
      first_name: string;
      last_name: string;
    };
    model: string;
    sn: string;
    lc_can_sn: string;
    sim_sn: string;
    sim_pin: string;
    sim_puk: string;
    mobile_number: string;

    constructor(input?){
        input = input || {};
        this.id = input.id || '';
        this.imei = input.imei || '';
        this.is_online = input.is_online || '';
        this.organisation = input.organisation || {};
        this.created_by = input.created_by || {};
        this.routerLink = "modification/tracker/"+this.id || '';
        this.model = input.model || '';
        this.sn = input.sn || '';
        this.lc_can_sn = input.lc_can_sn || '';
        this.sim_sn = input.sim_sn || '';
        this.sim_pin = input.sim_pin || '';
        this.sim_puk = input.sim_puk || '';
        this.mobile_number = input.mobile_number || '';
    }

}