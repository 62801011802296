import { Component, OnInit } from '@angular/core';
import { LocalStoreService } from '../../shared/services/local-store.service';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  constructor(
    private localStoreService : LocalStoreService,
    private router : Router,
    private authService : AuthService
  ) { }

  ngOnInit() {
    if(!this.localStoreService.getItem("currentUser")){
     //this.router.navigate(['session/login']);
    }
  }

  logout(){
    this.authService.signout();
  }

  settings(){
    this.router.navigate(['/settings']);
  }

  getUsername() : string{
    if(this.localStoreService.getItem("currentUser")){
      return this.localStoreService.getItem("currentUser")['name'];
    }
    //this.router.navigate(['session/login']);
    return "Not Logged In";
  }

}
