import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Tracker } from '../models/tracker.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TrackerService {

  constructor(private http : HttpClient) { }

  getAllTrackers(){
    return this.http.get(`${environment.baseUrl}/tracker`).pipe(map(res =>{
      let tmp : Tracker[] = [];
      for(let rsp of res['data']){
        tmp.push(new Tracker(rsp));
      }
      return tmp;
    }
    ));
   // return this.http.get(`${environment.baseUrl}/tracker`);
  }

  getTracker(id : number){
    return this.http.get(`${environment.baseUrl}/tracker/`+id);
  }

  deleteTracker(id : number){
    return this.http.delete(`${environment.baseUrl}/tracker/`+id);
  }

  createTracker(formData){
    return this.http.post(`${environment.baseUrl}/tracker`,formData);
  }

  updateTracker(id : number, userData){
    return this.http.put(`${environment.baseUrl}/tracker/`+id, userData);
  }

  loadLastLocations(){
    return this.http.get(`${environment.baseUrl}/tracker/lastknownlocation`);
  }

  loadRawTrackerData(id,params){
    return this.http.get(`${environment.baseUrl}/tracker/`+id+`/raw`,{params:params});
  }

}
